import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import Log from 'laravel-mix/src/Log';

// init controller
const controller = new ScrollMagic.Controller();



/* *** *** *** ***
** 1幕目
*** *** *** *** */

/*
    0~1200
    シーンをピン留め
    */
new ScrollMagic.Scene({
    duration: 1200,
    offset: 0
})
.setPin('#firstview')
.addTo(controller);




/*
    0~400
    カーテンを開ける
*/
new ScrollMagic.Scene({
    duration: 400,
    offset: 0
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#firstview_layer").css({
        "visibility": "visible"
    });
})
.addTo(controller);


/*
    400~800
    カーテンを開ける
*/
new ScrollMagic.Scene({
    duration: 800,
    offset: 0
})
.on("progress", function (prog) {
    const p = prog.progress;
    $(".firstview_layer_header").css({
        top: - p * 100 + "%",
    });
})
.addTo(controller);



/*
    400~1200
    カーテンを開ける
*/
new ScrollMagic.Scene({
    duration: 800,
    offset: 0
})
.on("progress", function (prog) {
    const p = prog.progress;
    $(".firstview_layer_title").css({
        top: 50 - p * 100 + "%",
    });
})
.addTo(controller);


/*
    400~1200
    カーテンを開ける
*/
new ScrollMagic.Scene({
    duration: 300,
    offset: 400
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#firstview_layer").css({
        "visibility": "hidden"
    });
    $("#about_layer").css({
        // "z-index": 9,
        opacity: p,
    });
})
.addTo(controller);


/*
    400~1200
    カーテンを開ける
*/
new ScrollMagic.Scene({
    duration: 400,
    offset: 600
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#about_layer_main").css({
        opacity: p,
    });
})
.addTo(controller);
